import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QNAP QVR Pro use cameras PIR Sensor",
  "path": "/Frequently_Asked_Question/QNAP_QVR_Pro_use_cameras_PIR_sensor/",
  "dateChanged": "2021-11-26",
  "author": "Mike Polinowski",
  "excerpt": "How can I use my cameras PIR sensor to trigger motion alarms instead of of using the internal motion detection?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - QNAP QVR Pro use cameras PIR Sensor' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='How can I use my cameras PIR sensor to trigger motion alarms instead of of using the internal motion detection?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/QNAP_QVR_Pro_use_cameras_PIR_sensor/' locationFR='/fr/Frequently_Asked_Question/QNAP_QVR_Pro_use_cameras_PIR_sensor/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "qnap-qvr-pro-use-cameras-pir-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#qnap-qvr-pro-use-cameras-pir-sensor",
        "aria-label": "qnap qvr pro use cameras pir sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QNAP QVR Pro use cameras PIR Sensor`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How can I use my cameras PIR sensor to trigger motion alarms instead of of using the internal motion detection?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You first have to make sure to `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/QNAP_QVR_Pro/#adding-a-camera"
      }}>{`add your camera`}</a>{` either via the INSTAR preset or the ONVIF T profile. When configuring the motion detection trigger in the `}<strong parentName="p">{`Motion Detection Settings`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49976b27f4132d840d5d7376af462612/1d553/QVRPro2_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABrElEQVQoz1WQW3MSQRCF+f8Plv4Fn1J5UbnsVohYBEyZMlETIGwSSaxoFgLsLgw7szvTt7EW0NJT89I99XWfPrU0SeI4TpMUCiMIgMwADI6IGEDAATMBsgMgIgAuLSIy4nq+rLGIAwfOIgISMiELM5MQEiEiOFsSIVTj0P8j8b5mrYtuH66j+9F4Et39GI0no/Hk+uZhWHW+Xw5vvg2i6l1FF4Pbsy+Di6/DUXR/NbrLVqqmTfGm+eH1QbN11H/b7LTa/WbYDRphELwPjk9b7X4j7L4LumH7Y6Pzqd4+DY569c5ZPTyJp/Oa9z4v3NP0p/e0SFeAyKWCPJGtSSJSar03LLJ3TNZj6b2v4M/n54eHB8YUqcodYK5Wq9kjmByRANABEjERG1NorRGJwGGpmbmCT3q9l69eqI2OZ3NjDOi1zhYIlqlKz1piZO99kiRZlol4ActgRaSC1xvzNJ0x0/MyRaRSK734xWUu4gGcUso6uzthnzOhuHIPq42exY8I8JysjSnKTbZZpYgoItba5TJxzu1g2Z5dbd7BslMVx588mLfV359dWP9r2/kN3mcvYtf6s5oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49976b27f4132d840d5d7376af462612/e4706/QVRPro2_13.avif 230w", "/en/static/49976b27f4132d840d5d7376af462612/d1af7/QVRPro2_13.avif 460w", "/en/static/49976b27f4132d840d5d7376af462612/7f308/QVRPro2_13.avif 920w", "/en/static/49976b27f4132d840d5d7376af462612/d7961/QVRPro2_13.avif 1159w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49976b27f4132d840d5d7376af462612/a0b58/QVRPro2_13.webp 230w", "/en/static/49976b27f4132d840d5d7376af462612/bc10c/QVRPro2_13.webp 460w", "/en/static/49976b27f4132d840d5d7376af462612/966d8/QVRPro2_13.webp 920w", "/en/static/49976b27f4132d840d5d7376af462612/69f9d/QVRPro2_13.webp 1159w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49976b27f4132d840d5d7376af462612/81c8e/QVRPro2_13.png 230w", "/en/static/49976b27f4132d840d5d7376af462612/08a84/QVRPro2_13.png 460w", "/en/static/49976b27f4132d840d5d7376af462612/c0255/QVRPro2_13.png 920w", "/en/static/49976b27f4132d840d5d7376af462612/1d553/QVRPro2_13.png 1159w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49976b27f4132d840d5d7376af462612/c0255/QVRPro2_13.png",
              "alt": "QNAP QVR Pro use cameras PIR Sensor",
              "title": "QNAP QVR Pro use cameras PIR Sensor",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You now have the option to toggle between the internal QVR Pro detection and your external camera motion detection:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49976b27f4132d840d5d7376af462612/1d553/QVRPro2_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABrElEQVQoz1WQW3MSQRCF+f8Plv4Fn1J5UbnsVohYBEyZMlETIGwSSaxoFgLsLgw7szvTt7EW0NJT89I99XWfPrU0SeI4TpMUCiMIgMwADI6IGEDAATMBsgMgIgAuLSIy4nq+rLGIAwfOIgISMiELM5MQEiEiOFsSIVTj0P8j8b5mrYtuH66j+9F4Et39GI0no/Hk+uZhWHW+Xw5vvg2i6l1FF4Pbsy+Di6/DUXR/NbrLVqqmTfGm+eH1QbN11H/b7LTa/WbYDRphELwPjk9b7X4j7L4LumH7Y6Pzqd4+DY569c5ZPTyJp/Oa9z4v3NP0p/e0SFeAyKWCPJGtSSJSar03LLJ3TNZj6b2v4M/n54eHB8YUqcodYK5Wq9kjmByRANABEjERG1NorRGJwGGpmbmCT3q9l69eqI2OZ3NjDOi1zhYIlqlKz1piZO99kiRZlol4ActgRaSC1xvzNJ0x0/MyRaRSK734xWUu4gGcUso6uzthnzOhuHIPq42exY8I8JysjSnKTbZZpYgoItba5TJxzu1g2Z5dbd7BslMVx588mLfV359dWP9r2/kN3mcvYtf6s5oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49976b27f4132d840d5d7376af462612/e4706/QVRPro2_13.avif 230w", "/en/static/49976b27f4132d840d5d7376af462612/d1af7/QVRPro2_13.avif 460w", "/en/static/49976b27f4132d840d5d7376af462612/7f308/QVRPro2_13.avif 920w", "/en/static/49976b27f4132d840d5d7376af462612/d7961/QVRPro2_13.avif 1159w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49976b27f4132d840d5d7376af462612/a0b58/QVRPro2_13.webp 230w", "/en/static/49976b27f4132d840d5d7376af462612/bc10c/QVRPro2_13.webp 460w", "/en/static/49976b27f4132d840d5d7376af462612/966d8/QVRPro2_13.webp 920w", "/en/static/49976b27f4132d840d5d7376af462612/69f9d/QVRPro2_13.webp 1159w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49976b27f4132d840d5d7376af462612/81c8e/QVRPro2_13.png 230w", "/en/static/49976b27f4132d840d5d7376af462612/08a84/QVRPro2_13.png 460w", "/en/static/49976b27f4132d840d5d7376af462612/c0255/QVRPro2_13.png 920w", "/en/static/49976b27f4132d840d5d7376af462612/1d553/QVRPro2_13.png 1159w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49976b27f4132d840d5d7376af462612/c0255/QVRPro2_13.png",
              "alt": "QNAP QVR Pro use cameras PIR Sensor",
              "title": "QNAP QVR Pro use cameras PIR Sensor",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that your camera is `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`configured to use it's PIR sensor`}</a>{` - preferably coupled with the motion detection areas.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      